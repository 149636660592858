import { Box, Typography, Link, IconButton } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ProgressBar } from '@percent/hosted-validation-form/ui';
import { ReactComponent as PercentLogo } from '@percent/hosted-validation-form/assets/icons/percent-logo.svg';
import { ReactComponent as ArrowLeft } from '@percent/hosted-validation-form/assets/icons/arrow-left.svg';
import { usePartnerConfig } from '@percent/hosted-validation-form/hooks';
import { PRIVACY_POLICY_URL } from '@percent/hosted-validation-form/constants/privacyPolicyUrl';
import { TERMS_URL } from '@percent/hosted-validation-form/constants/termsUrl';
import { PERCENT_URL } from '@percent/hosted-validation-form/constants/percentUrl';

import { CardProps } from './Card.types';
import * as Styles from './Card.styles';

export const Card = ({ shouldContainBackButton, children, title, subTitle, isError, goBack }: CardProps) => {
  const { partnerConfig, setPartnerConfig } = usePartnerConfig();
  const { t } = useTranslation();

  useEffect(() => {
    if (isError && partnerConfig) {
      setPartnerConfig({
        ...partnerConfig,
        configuration: { ...partnerConfig.configuration, primaryColor: '#ffffff' },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return (
    <Box sx={Styles.Card}>
      {partnerConfig && !isError && (
        <Box sx={Styles.Logo} component="img" alt="logo" src={partnerConfig?.configuration.logo} />
      )}
      {shouldContainBackButton && (
        <IconButton onClick={goBack} sx={Styles.BackButton} aria-label="back">
          <ArrowLeft />
        </IconButton>
      )}
      {partnerConfig && !isError && <ProgressBar />}
      {title && <Typography sx={Styles.Title(!!subTitle)}>{title}</Typography>}
      {subTitle && <Typography sx={Styles.SubTitle}>{subTitle}</Typography>}
      <Box sx={Styles.Children}>{children}</Box>
      <Box sx={Styles.Divider} />
      <Box sx={Styles.Footer}>
        <Typography>
          <Link underline="none" target="_blank" href={PERCENT_URL}>
            <PercentLogo /> Powered by Percent •{' '}
          </Link>
          <Link underline="none" target="_blank" href={TERMS_URL}>
            {t('hostedValidation.footer.terms')} •{' '}
          </Link>
          <Link underline="none" target="_blank" href={PRIVACY_POLICY_URL}>
            {t('hostedValidation.footer.privacy')}
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};
