import { Box, Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PercentLogo } from '@percent/hosted-validation-form/assets/icons/percent-logo.svg';
import { PRIVACY_POLICY_URL } from '@percent/hosted-validation-form/constants/privacyPolicyUrl';
import { TERMS_URL } from '@percent/hosted-validation-form/constants/termsUrl';
import { PERCENT_URL } from '@percent/hosted-validation-form/constants/percentUrl';

import { BasicCardProps } from './BasicCard.types';
import * as Styles from './Card.styles';

//TODO: abstract to a base level component for rendering cards and a higher-level card and an error card passing different things
export const BasicCard = ({ children }: BasicCardProps) => {
  const { t } = useTranslation();

  return (
    <Box sx={Styles.Card}>
      <Box sx={Styles.Children}>{children}</Box>
      <Box sx={Styles.Divider} />
      <Box sx={Styles.Footer}>
        <Typography>
          <Link underline="none" target="_blank" href={PERCENT_URL}>
            <PercentLogo /> Powered by Percent •{' '}
          </Link>
          <Link underline="none" target="_blank" href={TERMS_URL}>
            {t('hostedValidation.footer.terms')} •{' '}
          </Link>
          <Link underline="none" target="_blank" href={PRIVACY_POLICY_URL}>
            {t('hostedValidation.footer.privacy')}
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};
