import { environments } from '../constants/environments';

export const environment = process.env.NX_REACT_APP_ENV;

const envConfig = (() => {
  const obj = {
    urls: {
      api: '',
    },
  };

  switch (environment) {
    case 'Test':
    case environments.LOCAL:
      obj.urls.api = 'https://staging-api.poweredbypercent.com/v1/';
      break;
    case environments.STAGING:
      obj.urls.api = 'https://staging-api.poweredbypercent.com/v1/';
      break;
    case environments.PREPROD:
      obj.urls.api = 'https://api.pre-production.goodstack.io/v1/';
      break;
    case environments.QA:
      obj.urls.api = 'https://qa-api.poweredbypercent.com/v1/';
      break;
    case environments.SANDBOX:
      obj.urls.api = 'https://sandbox-api.poweredbypercent.com/v1/';
      break;
    case environments.PRODUCTION:
      obj.urls.api = 'https://api.poweredbypercent.com/v1/';
      break;
    default:
      obj.urls.api = 'https://staging-api.poweredbypercent.com/v1/';
      break;
  }

  return obj;
})();

export const config = {
  ...envConfig,
  version: '1.0.0',
};
