import { Box, Link, Typography } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Card, Stepper, Button, Checkbox, Error, Loader } from '@percent/hosted-validation-form/ui';
import { useValidationStepData } from '../../validationStepContext/useValidationStepData/useValidationStepData';
import * as Styles from '../HostedForm.styles';
import { PRIVACY_POLICY_URL } from '@percent/hosted-validation-form/constants/privacyPolicyUrl';
import { TERMS_URL } from '@percent/hosted-validation-form/constants/termsUrl';
import { ReactComponent as Expand } from '@percent/hosted-validation-form/assets/icons/expand.svg';

import { TermsProps } from './Terms.types';

export const Terms = ({ handleFormSubmit, isLoading, isError }: TermsProps) => {
  const { handleBack } = useValidationStepData();
  const [isChecked, setIsChecked] = useState(false);
  const { t } = useTranslation();

  const label = (
    <Typography sx={Styles.Label} variant="h6">
      <Trans t={t} i18nKey="hostedValidation.terms.agreeTerms">
        I agree to the{' '}
        <Link sx={Styles.Link} target="_blank" href={TERMS_URL} rel="noopener noreferrer">
          terms & conditions <Expand />
        </Link>
        and{' '}
        <Link sx={Styles.Link} target="_blank" href={PRIVACY_POLICY_URL} rel="noopener noreferrer">
          privacy policy <Expand />
        </Link>
      </Trans>
    </Typography>
  );

  if (isLoading) {
    return (
      <Card shouldContainBackButton={false}>
        <Loader />
      </Card>
    );
  }

  if (isError) {
    return (
      <Card shouldContainBackButton={false} isError={true}>
        <Error
          title={t('hostedValidation.errorMessage.somethingWentWrong')}
          subtitle={t('hostedValidation.errorMessage.reloadPage')}
          variant={Error.variants.INTERNAL}
        />
      </Card>
    );
  }

  return (
    <Card
      shouldContainBackButton
      goBack={handleBack}
      title={t('hostedValidation.title.almostFinished')}
      subTitle={t('hostedValidation.title.firstConfirm')}
    >
      <Box sx={Styles.Terms}>
        <Stepper />
        <Checkbox
          checked={isChecked}
          name="agreement"
          label={label}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setIsChecked(event.target.checked)}
          disabled={false}
        />
      </Box>
      <Button isDisabled={!isChecked} onClick={handleFormSubmit}>
        {t('hostedValidation.button.next')}
      </Button>
    </Card>
  );
};
