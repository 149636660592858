import i18next, { i18n as i18nInstance } from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';

import { namespaces, defaultLang } from './i18n.constants';

const resources = {
  en: { translations: require('@percent/hosted-validation-form/i18n/data/en.json') },
  'en-GB': { translations: require('@percent/hosted-validation-form/i18n/data/en-GB.json') },
  'fr-FR': { translations: require('@percent/hosted-validation-form/i18n/data/fr-FR.json') },
  'de-DE': { translations: require('@percent/hosted-validation-form/i18n/data/de-DE.json') },
  'es-ES': { translations: require('@percent/hosted-validation-form/i18n/data/es-ES.json') },
  'pt-BR': { translations: require('@percent/hosted-validation-form/i18n/data/pt-BR.json') },
};

const createI18n = (lang: string): i18nInstance => {
  const i18n = i18next.createInstance().use(initReactI18next);
  i18n.use(detector).init({
    detection: {
      order: ['querystring', 'navigator', 'htmlTag'],
      lookupQuerystring: 'lng',
      caches: [],
    },
    resources,
    fallbackLng: {
      fr: ['fr-FR'],
      de: ['de-DE'],
      es: ['es-ES'],
      pt: ['pt-BR'],
      default: [lang],
    },
    ns: namespaces.translations,
    react: {
      useSuspense: false,
    },
  });
  return i18n;
};
export const i18n = createI18n(defaultLang);
