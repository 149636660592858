import { useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';

import { HostedFormStep } from '@percent/hosted-validation-form/app/HostedForm/HostedForm/HostedForm.types';
import { useAnalytics } from '@percent/hosted-validation-form/hooks/useAnalytics/useAnalytics';
import { usePartnerConfig } from '@percent/hosted-validation-form/hooks/usePartnerConfig/usePartnerConfig';
import { getDeviceType } from '@percent/hosted-validation-form/utils/deviceType';
import { redact } from '@percent/hosted-validation-form/utils/redact';
import { redactToken } from '@percent/hosted-validation-form/utils/redactToken';

const redactPageData = (input: string) => redact(/validationinvite_[a-zA-Z0-9-]+/, redactToken(input));

const pageRedact = {
  url: redactPageData(window?.location?.href),
  search: redactPageData(window?.location?.search),
  referrer: redactPageData(document?.referrer),
  path: redactPageData(window?.location?.pathname),
};

const defaultOptions = { domain: 'Validations', channel: 'Hosted' };

const defaultContext = {
  app: { name: 'Hosted Validation Form', id: process.env.NX_REACT_APP_VERCEL_GIT_COMMIT_SHA },
  device: { type: getDeviceType() },
  ip: '0.0.0.0',
  ...pageRedact,
  page: {
    ...pageRedact,
  },
};

export const useHostedValidationsFormAnalytics = () => {
  const analytics = useAnalytics();
  const { partnerConfig } = usePartnerConfig();
  const { getValues } = useFormContext();
  const [analyticsLoaded, setAnalyticsLoaded] = useState<boolean>(!!analytics);

  const page = async (step: HostedFormStep, options?: Record<string, unknown>) =>
    analytics?.page(
      'Step',
      step,
      {
        ...defaultOptions,
        ...options,
        partnerName: partnerConfig?.partner?.name,
        partnerId: partnerConfig?.partner?.id,
        hostedConfigurationId: partnerConfig?.hostedConfigurationId,
        organisationId: getValues('organisationId') || undefined,
        ...pageRedact,
      },
      { context: defaultContext },
    );

  const identify = async ({ traits }: { traits?: Record<string, unknown> }) =>
    analytics?.identify(undefined, { ...traits }, { context: defaultContext });

  const track = async (name: string, options: Record<string, unknown>) =>
    analytics?.track(
      name,
      {
        ...defaultOptions,
        ...options,
        partnerName: partnerConfig?.partner?.name,
        partnerId: partnerConfig?.partner?.id,
        hostedConfigurationId: partnerConfig?.hostedConfigurationId,
        organisationId: getValues('organisationId') || undefined,
        ...pageRedact,
      },
      { context: defaultContext },
    );

  useEffect(() => {
    setAnalyticsLoaded(!!analytics);
  }, [analytics]);

  return {
    page,
    identify,
    track,
    analyticsLoaded,
  };
};
