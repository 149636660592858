import { Controller, useFormContext } from 'react-hook-form';
import { Box, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Card,
  Error,
  FileUpload,
  Input,
  Loader,
  Select,
  CountrySelect,
  CountryType,
} from '@percent/hosted-validation-form/ui';
import { HostedFormStep } from '../../HostedForm.types';
import { ReactComponent as PlusIcon } from '@percent/hosted-validation-form/assets/icons/plus.svg';
import { useValidationStepData } from '../../validationStepContext/useValidationStepData/useValidationStepData';
import { useCountries } from '../../../useCountries/useCountries';
import * as Styles from '../HostedForm.styles';
import { useHostedValidationsFormAnalytics } from '@percent/hosted-validation-form/hooks/useHostedValidationsFormAnalytics/useHostedValidationsFormAnalytics';

import { RegisterOrganisationProps } from './RegisterOrganisation.types';
import { useEffect } from 'react';

type FormFieldValues = {
  registryName: string;
  registryId: string;
  organisationName: string;
  unknownRegistryName: string;
  file: File;
};

export const RegisterOrganisation = ({
  isLoading,
  isError,
  registries,
  isInitiallyFetching,
  onUpload,
  isUploading,
}: RegisterOrganisationProps) => {
  const { setCountry, country, handleNext, handleBack } = useValidationStepData();
  const countries = useCountries();
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    watch,
    resetField,
  } = useFormContext<FormFieldValues>();
  const { t } = useTranslation();
  const { track } = useHostedValidationsFormAnalytics();

  const CANT_FIND_REGISTRY = t('hostedValidation.typography.cantFindRegistryName');

  const handleCountryChange = (newValue: CountryType | null) => {
    setCountry(newValue?.code || '');
    setValue('registryName', '');
  };

  const handleNavigateNext = () => {
    if (getValues('registryName') === CANT_FIND_REGISTRY) {
      setValue('registryName', getValues('unknownRegistryName'));
    }

    return handleNext(HostedFormStep.ORGANISATION_ADDRESS);
  };

  const resetUnknownRegistryName = () =>
    getValues('registryName') !== CANT_FIND_REGISTRY && resetField('unknownRegistryName');

  const handleNavigateBack = () => {
    setValue('registryName', '');
    setValue('registryId', '');
    setValue('organisationName', '');
    handleBack();
  };

  useEffect(() => {
    if (isError) {
      track('Viewed RegisterOrganisation Error', {
        errorLabel: Error.variants.INTERNAL,
      });
    }
  }, [isError]);

  if (isError) {
    return (
      <Card shouldContainBackButton={false} isError={true}>
        <Error
          title={t('hostedValidation.errorMessage.somethingWentWrong')}
          subtitle={t('hostedValidation.errorMessage.reloadPage')}
          variant={Error.variants.INTERNAL}
        />
      </Card>
    );
  }

  return (
    <Card shouldContainBackButton goBack={handleNavigateBack} title={t('hostedValidation.title.registerOrg')}>
      {isInitiallyFetching && <Loader />}
      {!isInitiallyFetching && !isError && (
        <>
          <Box sx={Styles.FlexContainer}>
            <Controller
              name="organisationName"
              control={control}
              render={({ field: { name, value, onChange } }) => (
                <Input
                  value={value}
                  name={name}
                  label={t('hostedValidation.label.organizationName')}
                  placeholder={t('hostedValidation.placeholder.org')}
                  onChange={onChange}
                  errorMessage={errors.organisationName?.message}
                />
              )}
            />
            <CountrySelect
              isDisabled={isLoading}
              countries={countries}
              label={t('hostedValidation.label.country')}
              placeholder={t('hostedValidation.placeholder.country')}
              value={country}
              onChange={handleCountryChange}
            />
            <Controller
              name="registryName"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select
                  isDisabled={isLoading}
                  label={t('hostedValidation.label.registryName')}
                  placeholder={t('hostedValidation.placeholder.registryName')}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    resetUnknownRegistryName();
                  }}
                >
                  {registries?.map((registry, index) =>
                    Select.renderMenuItem(registry.name, registry.name, `registry-name-option-${index}`),
                  )}
                  <MenuItem
                    sx={Styles.MenuItem}
                    value={CANT_FIND_REGISTRY}
                    onClick={() => {
                      track('Element Clicked', {
                        action: 'menu item',
                        label: 'I can’t find my registry name. I want to add it.',
                      });
                    }}
                  >
                    {value !== CANT_FIND_REGISTRY && <PlusIcon />}
                    {CANT_FIND_REGISTRY}
                  </MenuItem>
                </Select>
              )}
            />
            {watch('registryName') === CANT_FIND_REGISTRY && (
              <Controller
                name="unknownRegistryName"
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <Input
                    value={value}
                    name={name}
                    label={t('hostedValidation.label.registryName')}
                    placeholder={t('hostedValidation.placeholder.registryName')}
                    onChange={onChange}
                    errorMessage={errors.unknownRegistryName?.message}
                  />
                )}
              />
            )}
            <Controller
              name="registryId"
              control={control}
              render={({ field: { name, value, onChange } }) => (
                <Input
                  value={value}
                  name={name}
                  label={t('hostedValidation.label.registryId')}
                  placeholder={t('hostedValidation.placeholder.registryId')}
                  onChange={onChange}
                  errorMessage={errors.registryId?.message}
                />
              )}
            />
            <FileUpload
              isUploading={isUploading}
              onUpload={onUpload}
              label={t('hostedValidation.label.evidenceFile')}
              name="evidence"
              helperText={t('hostedValidation.validation.fileValid', { minFileSize: '32KB', maxFileSize: '5MB' })}
            />
          </Box>
          <Box sx={Styles.RegisterButtonContainer}>
            <Button
              isDisabled={
                !country ||
                !watch('organisationName') ||
                (watch('registryName') === CANT_FIND_REGISTRY && !watch('unknownRegistryName')) ||
                !watch('registryName') ||
                !watch('registryId') ||
                !watch('file') ||
                Object.keys(errors).length > 0
              }
              onClick={handleNavigateNext}
            >
              {t('hostedValidation.button.next')}
            </Button>
          </Box>
        </>
      )}
    </Card>
  );
};
